export const environment = {
  useEmulators: false,
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBhg2jgp3Ppw9b2H_j2pFe4eJblt5fDBVY',
    authDomain: 'gbl-ist-ve-dlaaas.firebaseapp.com',
    databaseURL: 'https://gbl-ist-ve-dlaaas-default-rtdb.firebaseio.com',
    projectId: 'gbl-ist-ve-dlaaas',
    storageBucket: 'gbl-ist-ve-dlaaas.appspot.com',
    messagingSenderId: '876376300466',
    appId: '1:876376300466:web:bbe4b42e063f7f535262ab',
    experimentalForceLongPolling: true,
  },
};
